import Vue from 'vue'
import router from './router'
import './assets/styles.css'
import AfterGame from '@/AfterGame.vue'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(AfterGame)
}).$mount('#app')
