<template>
  <div class="page">
    <div class="poster" style="margin-bottom: 0">
      <img src="@/assets/splash.jpg" />
    </div>
    <div class="poster" style="max-width: 1280px; margin: auto; margin-bottom: 60px;">
      <div style="padding:56.25% 0 0 0;position:relative; width: 100%;">
        <iframe src="https://player.vimeo.com/video/884900401?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="B20teaserfinal"></iframe>
        <script type="application/javascript" src="https://player.vimeo.com/api/player.js"></script>
      </div>
    </div>

    <div class="segment bg-brown">
      <div class="content narrow">
        <div class="post no-bg">
          <div class="post-content-wrapper big-text">
            <div class="post-content centered-image">
              <img src="@/assets/2024/20-years-badge-BERGET-EVENTS.png" alt="">
            </div>
            <div class="post-header text-highlight-red">BERGET 20 <span style="color:white"> - THE FINAL BRIDGE</span></div>
            <pre class="post-content">
              Now is the time... After almost two decades of Berget Events we are planning an epic celebration of the 20th anniversary this summer. Our team have been hard at work making sure that this summer's event will be extraordinary.

              We have a beautiful, large game area with rivers, bridges, lakes and deep forests, perfect for airsoft.

              In the center of the game area we will build up an abandoned military base that will be held by one of the smaller teams (Yellow) and function as the main objective for the two opposing teams (Red & Blue). We will add a fourth team called Raiders (Black & white). They will roam the game area as the native cartel / MC gang and a guns for hire syndicate/clan.

              We will use shipping containers, excavators, and a lot of props to create a battlefield completely unlike anything you have experienced on Berget before. There will be an extensive network of trenches, bunkers and CQB buildings in the central part of the map, and several complex objects that will require teamwork and multiple stages to conquer.

              The two bigger teams will start on opposite sides of the playing area and only one rebel force will fight for its survival in the middle.

              There will be a civil trading station and an associated camp where all three sides can do business or where tired soldiers can escape the harsh reality for a while.

              We've spent time understanding how our planned game area will work to ensure better movement of people and vehicles, and as below we will tune how vehicles, road clearing etc works to bring the balance needed for the best possible experience.

              The vehicles will last much longer before being knocked out and a "non-penetrating hit" sound/flash will be introduced along with more hit points to counter the increased amount of anti-tank weapons. Tanks and the heaviest class will now be much harder to eliminate and will be used as break through vehicles.

              The game area will have several bridges that will have "new rules" regarding blowing up and repairing.

              New for this year is that all capture points will not be visible on the map from the start, but these must be found by your own team to be activated.

              We will provide Starlink and Wi-Fi to all bases as well as some capture points on the map for Wi-Fi access.

              The shop with airsoft equipment, bullets and guns will be the biggest we ever had, with an amazing selection you won't see at any other airsoft event.

              We have many more epic surprises planned both in and off game to ensure that Berget 20 will be an anniversary that all players remember for a long time!
            </pre>
            <div class="post-date">2023.11.15 - 20:00 CET (GMT +1) </div>
          </div>
        </div>
      </div>
    </div>

    <div class="segment" v-if="news.length > 0">
      <div class="content">
        <div class="header">LATEST <span class="text-highlight">NEWS</span></div>

        <div class="post" v-for="post in news" :key="post.Id">
          <div v-if="post.Cover.length > 0" class="image"><img :src="getImageURL(post)"></div>
          <div class="post-content-wrapper">
            <div class="post-header">{{ post.Title }}</div>
            <pre class="post-content" v-html="post.Content"></pre>
            <div class="post-date">{{ formatDate(post.Date) }}</div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      news: [{
        Id: '5',
        Title: 'Chrono rules',
        Cover: [{ path: 'img/chrono.jpg' }],
        Content: `Please keep in mind that the chrono will be performed with your own bb’s, and the weight of the bb’s should be the weight you are intending to use during the event.

Please also remember the safety glasses.`,
        Date: '2024-06-19 22:42'
      }, {
        Id: '4',
        Title: 'Blue HQ ready!',
        Cover: [{ path: 'img/blue-ready.jpg' }],
        Content: `After much searching, we have now found a HQ team and a force commander with the right experience who will be able to lead the blue force (Coalition forces) this year. The HQ staff has extensive previous Berget experience and the selected commander has extensive experience in leading troops and planning operations.

We know we are late and will therefore speed up the work of putting together the structure of the Coalition forces and adding the necessary resources.

We are looking for the following. Anyone who can think of playing platoon leader for a group of 20-40 people (3-5 squads) should immediately send their application to <a href="mailto:info@berget-events.com">info@berget-events.com</a> with the topic Blue officer. If you have booked a ticket for special forces, you can expect an email before weekend about SF-trials.

We will post more information and other contact info on the coalition forces team's Discord shortly, make sure you join the discord channel.`,
        Date: '2024-05-21 09:42'
      }, {
        Id: '3',
        Title: 'Game area update!',
        Cover: [{ path: 'img/gameareanews.jpg' }],
        Content: `We can now confirm that the gaming area has been signed with landowners and other interested parties. It will be Backsjön outside Sollefteå. We are happy to have been trusted for another year, and with the experience gained from last year, we can use this area to its full potential.

The Safezone will be in the same location but the bases and access routes to the bases will change for the better.

The game area map will be released later. First to commanders and later to players.
First drive to Backsjön, north of Sollefteå.
GPS 63.321496820766555, 17.33317132369936

Then follow the Berget 20 signs to SafeZone and Check-in called Voyager Park.
GPS 63.3286073105406, 17.30695072074258`,
        Date: '2024-01-31 22:30'
      }, {
        Id: '2',
        Title: 'We are looking for a Commander',
        Cover: [{ path: 'img/wouldyouliketoknowmore.jpg' }],
        Content: `We are looking for an experienced leader who has experience in civilian or military leadership of large groups. You must also have a good stress tolerance and be able to work under difficult conditions. You will be responsible for a team of around 300 people and you will need to recruit a staff of 6 people to build your HQ. You must have experience from airsoft and understand the hobby.

Berget will provide as much support as needed in planning and you will have access to the other commanders as well as GM Crew during your pre game process.  HQ staff will receive free game tickets, sleeping accommodation and meals during the event and logistical support for base and HQ needs.

Send your application or recommendation to info@berget-events.com`,
        Date: '2024-01-24 20:00'
      }, {
        Id: 'lol',
        Title: 'Ticket Berget 20',
        Cover: [{ path: 'download/noco/berget-WWW/News/Cover/Vw_sf1a2J0ZYI4jhZ3.jpg' }],
        Content: 'The tickets for Berget 20 have been released! \n' +
          'You can find and book your tickets on our portal: \n' +
          '<a href="https://berget-portal.com/">https://berget-portal.com/</a>',
        Date: '2023-11-26 20:00'
      }]
    }
  },
  methods: {
    async loadNews () {
      const response = await fetch('https://berget-events.com/db/news')
      const result = await response.json()
      result.list.sort((a, b) => {
        return a.Id > b.Id ? -1 : 1
      })
      this.news = result.list
    },
    formatDate (date) {
      const time = date.substring(10, 16) + ' CET (GMT +1)'
      const prettyDate = date.substring(0, 10).replace(/-/g, '.')
      return `${prettyDate} - ${time}`
    },
    getImageURL (post) {
      const img = post.Cover[0] || {}
      if (img.url) return img.url
      if (img.path) return img.path.replace('download/', 'https://berget-events.com/uploads/')
    }
  },
  created () {
    this.loadNews()
  }
}
</script>
