<template>
  <div id="app" style="padding-top: 0">
    <div class="page">
      <div class="segment bg-brown">
        <div class="content narrow">
          <div class="post no-bg">
            <div class="post-content-wrapper big-text">
              <div class="post-content centered-image" >
                <img src="@/assets/2024/20-years-badge-BERGET-EVENTS.png" alt="">
              </div>
              <div class="post-header text-highlight-red"><span style="color:white">Berget 2.0 and beyond</span></div>
              <pre class="post-content">
                We would like to start with sending our heartfelt thanks to each and every one of you who have helped us to celebrate Berget´s 20th anniversary this summer. It has been a long journey, and across the last two decades Berget has always tried to do its own thing and stand in the forefront by trying to contribute to the hobby. Sometimes we admittedly missed the mark with our new ideas, but other times we feel that the efforts have really paid off and helped us to set a new standard.

But Berget would never have been what it is if not for the immense support and feedback from the players over the years. We are sincerely overwhelmed that you have helped us do what we love to do during the past 20 years during both highs and lows.

With Berget 20 now being over we want to release a first glimpse of our vision to continue bringing Berget into its 2.0 future. We have started by laying out a five year plan, but the vision goes much further than that.

The top most important pillar in our 2.0 vision is continued innovation. We feel that innovation historically has been one of our strongest points, and it is our ambition to double down on this and continue to break new ground in what can be expected from an airsoft event. We aim to continue to iterate and improve on the current systems (Bavs, Capture points, real time online map, drone combat etc). But there are also some brand new and interesting projects on the near horizon. To name a few examples we are currently working on personal player profile in-game achievements connected and trackable from your user at our Berget portal. Through RFID this will be integrated to among other things the tracking of in-game progress such as mission success rates, contested capture points and respawn usage.

Another important fundament of our Berget 2.0 vision is player feedback and adequate actions and changes as a result of this. This fall we will be sending out a comprehensive feedback survey to all our current and past players. This will encompass everything from game-area feedback to logistics, game mechanics, rules, team balance and story set up. This feedback will be the base of the long term evolution of Berget.

We also put a high value on the continued recruitment of both senior and new crew members. Since many of us have been working with this for the better part of our lives, we are extremely happy to see our younger generations coming of age and doing their first work weeks at Berget. A big part of our vision is to secure the longevity of Berget through the passion and commitment that equals our own.

Financial funding for our vision has already been secured, but to see it to fruition we are currently in discussions with some potential partners to help contribute with their know-how and fresh perspectives on how to keep evolving the future of the air-soft hobby. We also understand that the game area is a very big factor in the whole experience, and are working hard to expand our park with brand new areas for future events.

We do once again want to thank all of you for the support over the past two decades, and we hope that you will continue to help us move Berget to the next level. Coming up closest is the comprehensive player feedback survey right after the summer, and after that we will announce the concept and release of the Berget 21 webpage on the 27th of october , which will be our first game on our Berget 2.0 journey.
            </pre>
              <div class="post-date">2024.06.30 - 11:00 CET (GMT +1) </div>
            </div>
          </div>
        </div>
      </div>

      <div class="poster" style="margin-top: 60px; margin-bottom: 10px;">
        <img src="@/assets/2024/splashB20.jpg" />
      </div>

      <div class="poster" style="margin-top: 60px; margin-bottom: 10px;">
        <img src="@/assets/2024/fog.jpg" />
      </div>

      <div class="poster">
        <video controls src="/boat.mp4" style="max-width: 1280px; margin: auto; margin-bottom: 60px;" />
      </div>

      <div class="poster" style="max-width: 1280px; margin: auto;">
        <div style="padding:56.25% 0 0 0;position:relative; width: 100%;">
          <iframe src="https://player.vimeo.com/video/884900401?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="B20teaserfinal"></iframe>
          <script type="application/javascript" src="https://player.vimeo.com/api/player.js"></script>
        </div>
      </div>

    </div>

    <div class="segment footer" style="padding: 60px 10px ">
        <span style="font-size: 19px; text-align: center">
          To contact us about the next game or other questions: <br> <br>
          <a href="mailto:info@berget-events.com">info@berget-events.com</a>
        </span>

    </div>
  </div>

</template>

<script>
export default {
  name: 'AfterGame',
  data () {
    return {
    }
  },
  methods: {
    formatDate (date) {
      const time = date.substring(10, 16) + ' CET (GMT +1)'
      const prettyDate = date.substring(0, 10).replace(/-/g, '.')
      return `${prettyDate} - ${time}`
    },
    getImageURL (post) {
      const img = post.Cover[0] || {}
      if (img.url) return img.url
      if (img.path) return img.path.replace('download/', 'https://berget-events.com/uploads/')
    }
  }
}
</script>
